
/* Global */
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&family=Schoolbell&display=swap');


.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus { 
  border: 1px solid #0d6efd; 
  background: #83b5ff;
  /* url(images/ui-bg_glass_100_fdf5ce_1x400.png) 50% 50% repeat-x; */
  font-weight: bold; 
  color: black; 
}



@media (max-width: 575px) {
  .card{
    background-position-y:  48px;
  }
  .landlord-attribute {
    line-height: 1.9;
  }
  h5.warning {
    font-size: 1.2rem;
  }
  #property-card{
    min-height: 20rem;
  }
}

.main {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-brand img {
  max-width: 300px;
  height: auto;
}

.mb-4{
  margin-bottom: 0;
}

.navbar-brand  {
  margin: auto;
}

.navbar-toggler{
  margin-right: 1rem;
}

.nav-link { 
  color: black;
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: .1rem;
  font-family: 'Courier', monospace;
}


/* Card */
.card{
 max-width: 801px;
 margin-left: auto;
 margin-right: auto;
 --bs-card-spacer-x: 0;
 --bs-card-spacer-y: 0;
}

.card-body{
  padding-top: 0;
}

.card header {
 background: linear-gradient(white, white 33px, pink 35px, pink 36px);
 height: 36px;
}

.card-lines{
    background: repeating-linear-gradient(white, white 25px, #9198e5 26px, #9198e5 27px);
    --bs-card-spacer-x: .75rem;
    min-height: 13rem;
    margin-left: auto;
    margin-right: auto;
    background-position-y: -5px;
}

.landlord-info{
  line-height:1.68rem ;
}

.landlord-grades h2{
  line-height: 1.1;

}

.grade-value {
  font-size: 1.5em;
  margin-left: auto;
  margin-right: 1rem;
}

.grade, .landlord-name{
  font-size: 2rem;

}

#text::before {
 content: "\"";
}
#text::after {
 content: "\"";
}
#title::after {
 content: " said:";
 font-size: 20px;
}

.title-label {
  font-size: 1em;
}

.landlord-attribute{
  font-weight: 700;
  line-height: 1.7;
}

.font-handwritten{
  font-family: 'Schoolbell', cursive;
}

.title-row {
  background-color: white;
  border-bottom: solid pink;
  --bs-gutter-x: 0;
}

.font-typewriter{
  font-family: Courier, monospace;
}

.font-coronette{
  font-family: Dunbar, serif;
}

.rating-block{
  background-color:#FAFAFA;
  border:1px solid #EFEFEF;
  padding:15px 15px 20px 15px;
  border-radius:3px;
}
.bold{
  font-weight:700;
}
.padding-bottom-7{
  padding-bottom:7px;
}


/* Warning */

h5.warning {
  font-family: 'Schoolbell';
  color: red;
  font-size: 1.5rem;
}
.warning{
  margin-bottom: 0;
}


#accordionwarning .accordion-body {
  font-family: 'Courier', monospace;
}

#accordionwarning .accordion-button::after {
  margin-left: auto;
}

#accordionwarning .accordion-button:not(.collapsed){
  background-color: transparent;
}

#accordionwarning .accordion-collapse{
  background-color: transparent;
}

.accordion-button{
  font-weight: 700;
}

.accordion-body {
  font-weight: 100;
  font-family: 'Schoolbell';
}

.accordion-button::after{
  margin: 0;
}

.accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: none
}

.accordion-collapse {
  background-color: white;
}

.accordion-button {
  padding: 0;
}


/* Property Page */

#map {
  height: 400px; 
}
.property-card .card{
  background-position-y: 23px;
}

.property-label{
  font-family: 'Courier', monospace;
  font-weight: 700;

}
.property-address{
  font-size: 1.2rem;
  font-family: 'Schoolbell', cursive !important;

}

#property-card {
  line-height: 1.68rem;
  min-height: 25rem;
  font-family: 'Schoolbell', cursive;
  font-weight: 700;
}
#property-card .card-lines{
  min-height: 21rem;
}

#property-card span{
  font-family: 'Courier', monospace;
}

.property-info {
    padding-left: 1rem;
    padding-right: 1rem;
}

#google_translate_element {
  text-align: center;
}

.leaflet-container {
  width: 100%;
  height: 40vh;
}

.yellow-grade {
  color:  #ffa700;
}

.green-grade {
  color: #2cba00;
}

.red-grade {
  color: #ff0000;
}

.transparent-background {
  background-color: transparent !important;
}

.action-accordion-body {
  font-weight: 400;
  font-family: "Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}


.disclaimer-box {
  font-size: 12px;
  font-style: italic;
}

body > #root > div {
  height: 100%;
  min-height: 90vh;
}







